import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Layout } from "../components/shared/layout";
import { Helmet } from "../components/shared/helmet";

import { PageTitle } from "../components/shared/page-title";
import { Gallery } from "../components/shared/gallery";

export default () => {
  const { img0, img1, img2, img3, img4 } = useStaticQuery(graphql`
    query {
      img0: file(relativePath: { eq: "galerie/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img1: file(relativePath: { eq: "galerie/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "galerie/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "galerie/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "galerie/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet title="Galerie" />
      <PageTitle>GALERIE</PageTitle>
      <Gallery
        fluids={[img0, img1, img2, img3, img4].map(
          data => data.childImageSharp.fluid
        )}
      />
    </Layout>
  );
};
