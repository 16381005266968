import React from "react";

import { Title } from "../title";
import styles from "./page-title.module.scss";

export const PageTitle: React.FC = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      <Title inverted>{children}</Title>
    </div>
  </div>
);
