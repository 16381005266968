import React from "react";
import Img, { FluidObject } from "gatsby-image";

import styles from "./gallery.module.scss";

type GalleryProps = {
  fluids: FluidObject[];
};

export const Gallery: React.FC<GalleryProps> = ({ fluids }) => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      {fluids.map((fluid, i) => (
        <div className={styles.item} key={i}>
          <Img fluid={fluid} />
        </div>
      ))}
    </div>
  </div>
);
